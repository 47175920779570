export function tabs() {
    document.querySelectorAll('[data-lf-tab]').forEach((button, index) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();

            let wrap = button.closest('.lf-tabs');

            if (!wrap) {
                return;
            }

            let name = button.getAttribute('data-lf-tab');
            let content = wrap.querySelector(`[data-lf-tab-name="${name}"]`);

            if (!content) {
                return;
            }

            wrap.querySelectorAll('[data-lf-tab]').forEach((button) => {
                button.classList.remove('active');
            });

            button.classList.add('active');

            wrap.querySelectorAll('[data-lf-tab-name]').forEach((content) => {
                content.classList.remove('active');
            });

            content.classList.add('active');
        });
    });
}
