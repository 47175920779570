import Flickity from 'flickity';

function toggleResizedClass(slider) {
    let resize = slider.prototype.resize;

    slider.prototype.resize = function () {
        this.element.classList.remove('resized');
        resize.call(this);
        this.element.classList.add('resized');
    };
}

export function carousels() {
    toggleResizedClass(Flickity);

    document.querySelectorAll('[data-lf-carousel]').forEach(function (element) {
        new Flickity(element, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            adaptiveHeight: false,
        });
    });

    window.dispatchEvent(new Event('resize'));
}
